export const languages = [
  'Golang',
  'Java',
  'Python3',
  'Shell Scripting',
];

export const frameworks = ['Gin/Mux', 'Flask'];

export const tools = [
  'Git & Github',
  'Bash',
  'Firefox Dev Tools',
  'Postman',
  'Warp Terminal',
  'Terraform',
];

export const systemAdmin = ['Rocky Linux', 'AWS ECS', 'Kubernetes (AWS EKS)', 'Github Actions'];
