import { useEffect } from 'react';
import styled from 'styled-components';

import { Section, SectionContent, SectionTitle } from '../style/styles';
import media from '../style/media';
import { languages, frameworks, tools, systemAdmin } from '../data/skills';
import sr from '../style/ScrollReveal';

const SkillsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${media.weirdMedium`
    flex-wrap: wrap;
  `}
`;

const SkillsCategory = styled.div`
  ${media.weirdMedium`
    width: 47%;
    margin-right: 5px;
    margin-bottom: 25px;
  `}
  .label {
    font-weight: 700;
    color: $black;
    text-transform: uppercase;
  }
  .item {
    margin: 3px 0;
    font-size: 1rem;
  }
`;

const Skills = () => {
  useEffect(() => {
    const config = {
      reset: false,
      duration: 600,
      easing: 'cubic-bezier(.694,0,.335,1)',
      scale: 1,
      viewFactor: 0.3,
    };

    sr.reveal('.skills', config);
  }, []);

  return (
    <Section className="skills">
      <SectionTitle>Skills</SectionTitle>
      <SectionContent>
        <SkillsContainer>
          <SkillsCategory>
            <div className="label">Languages</div>
            <ul>
              {languages.map((value, index) => {
                return <li className="item" key={index}>{value}</li>;
              })}
            </ul>
          </SkillsCategory>
          <SkillsCategory>
            <div className="label">Frameworks</div>
            <ul>
              {frameworks.map((value, index) => {
                return <li className="item" key={index}>{value}</li>;
              })}
            </ul>
          </SkillsCategory>
          <SkillsCategory>
            <div className="label">Tools</div>
            <ul>
              {tools.map((value, index) => {
                return <li className="item" key={index}>{value}</li>;
              })}
            </ul>
          </SkillsCategory>
          <SkillsCategory>
            <div className="label">System Admin tools</div>
            <ul>
              {systemAdmin.map((value, index) => {
                return <li className="item" key={index}>{value}</li>;
              })}
            </ul>
          </SkillsCategory>
        </SkillsContainer>
      </SectionContent>
    </Section>
  );
};

export default Skills;
