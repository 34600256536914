import { useEffect } from 'react';
import styled from 'styled-components';

import { Section, SectionContent, SectionTitle, ArrowStyle } from '../style/styles';
import media from '../style/media';
import { experience } from '../data/experience';
import sr from '../style/ScrollReveal';
import resume from '../resume.pdf';

const Jobs = styled.div`
  margin-bottom: 100px;
  .job {
    margin-bottom: 25px;
  }

  .time-place {
    display: flex;
    justify-content: space-between;
    ${media.mobile`
      display: block;
    `}
  }

  .company {
    font-weight: 700;
  }

  .time {
    font-size: 0.9rem;
    font-family: 'Inconsolata', monospace;
    ${media.mobile`
      font-size: 0.8rem;
    `}
  }

  .arrow-link {
    font-size: 1.25rem;
    &:after {
      width: 20px;
      height: 20px;
    }
  }
`;

const Experience = () => {
  useEffect(() => {
    const config = {
      reset: false,
      duration: 600,
      easing: 'cubic-bezier(.694,0,.335,1)',
      scale: 1,
      viewFactor: 0.2,
    };

    sr.reveal('.experience', config);
  }, []);


  return (
    <Section className="experience">
      <SectionTitle>Experience</SectionTitle>
      <SectionContent>
        <Jobs>
          {experience.map((value, index) => {
            return <div className="job" key={index}>
              <div className="time-place">
                <div className="company">
                  <a href={value.url} target="_blank" rel="noreferrer">{value.company}</a>
                </div>
                <div className="time">{value.time}</div>
              </div>
              <div className="position">{value.position}</div>
            </div>;
          })}
        </Jobs>
        <ArrowStyle href={resume} target="_blank" className="arrow-link" rel="noreferrer">View My Resume</ArrowStyle>
      </SectionContent>
    </Section>
  );
};

export default Experience;
