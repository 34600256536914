import { useEffect } from 'react';

import { Section, SectionTitle, SectionContent } from '../style/styles';
import sr from '../style/ScrollReveal';

const Background = () => {
  useEffect(() => {
    const config = {
      reset: false,
      duration: 600,
      easing: 'cubic-bezier(.694,0,.335,1)',
      scale: 1,
      viewFactor: 0.3,
    };

    sr.reveal('.background', config);
  }, []);

  return (
    <Section className="background">
      <SectionTitle>Background</SectionTitle>
      <SectionContent>
        <p>
          I'm currently a Software Engineer Intern at
          <a
            className="underline-link"
            rel="noreferrer"
            href="https://www.seniorly.com/"
            target="_blank"
          >
            &nbsp;Seniorly
          </a>{' '}
          working on improving backend and infrastructure (AWS) to make senior
          living accessible. I am currently pursuing my Master's in Computer
          Science at
          <a
            className="underline-link"
            rel="noreferrer"
            href="https://www.colorado.edu/"
            target="_blank"
          >
            University of Colorado, Boulder (CU Boulder).
          </a>
        </p>

        <p>
          As a software engineer, I enjoy building solutions to solve routine
          problems &mdash; solving real world problems and exposing to the open
          so that anyone could use it. My goal is to always build applications
          which help people while allowing me to continuously learn and evolve.
          I am enthusiastic about Linux System Administration and Networking.
        </p>

        <p>
          On my personal time, I love to host services on my home server and
          play around with networking devices like routers. I am currently
          learning about kubernetes and trying to migrate my homeserver from
          docker-compose based services to a K8S cluster.
        </p>

        <p>
          <strong>When I'm not in front of a terminal</strong>, I'm probably
          learning about finance, cooking new dishes or reading non-fiction
          books.
        </p>
      </SectionContent>
    </Section>
  );
};

export default Background;
