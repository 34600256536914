import { useState } from 'react';
import styled, { keyframes } from 'styled-components';

import { colorsAndFonts, transition } from '../style/constants';

import media from '../style/media';

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ProfileImage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin: 20px auto;
  display: block; // Centers the image in its container
  transition: width 0.3s ease, height 0.3s ease;
  animation: ${fadeInUp} 0.8s ease-out forwards;

  ${media.desktop`
    width: 180px;
    height: 180px;
  `}
  ${media.tablet`
    width: 150px;
    height: 150px;
  `}
  ${media.phablet`
    width: 120px;
    height: 120px;
  `}
  ${media.mobile`
    width: 100px;
    height: 100px;
  `}
`;

const Container = styled.header`
  padding: 120px 100px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 1440px;
  margin: 0 auto;

  ${media.weirdMedium`
    padding: 120px 70px;
  `}
  ${media.phablet`
    padding: 120px 50px;
  `}
  ${media.mobile`
    padding: 70px 50px;
  `}

  &__hello, &__tagline {
  }
`;

const Animation = keyframes`
  from {
    transform: none;
  }
  15% {
    transform: translate3d(-20%, 0, 0) rotate3d(0, 0, 1, -10deg);
  }
  30% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 7deg);
  }
  45% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -10deg);
  }
  60% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 5deg);
  }
  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -2deg);
  }
  to {
    transform: none;
  }
`;

const HelloIntro = styled.h1`
  font-weight: 300;
  font-size: 2.5rem;
  ${media.desktop`
    font-size: 2.25rem;
  `}
  ${media.tablet`
    font-size: 2rem;
  `}
  ${media.phablet`
    font-size: 1.75rem;
  `}
  ${media.smallMobile`
    font-size: 1.5rem;
  `}
  ${media.tinyMobile`
    font-size: 1.4rem;
  `}
  .emoji {
    width: 40px;
    height: 40px;
    ${media.desktop`
      width: 35px;
      height: 35px;
    `}
    ${media.tablet`
      width: 30px;
      height: 30px;
    `}
    ${media.smallMobile`
      width: 25px;
      height: 25px;
    `}
  }
  .wave-hand {
    background-image: url('./img/wave.png');
    margin-left: 10px;
    cursor: pointer;
  }

  .wave {
    animation-name: ${Animation};
  }

  .animated {
    animation-duration: 1s;
    animation-fill-mode: both;
  }
`;

const TagLine = styled.h2`
  font-weight: 300;
  max-width: 700px;
  .name {
    font-weight: 700;
  }
  .technologist {
    background-image: url('./img/technologist.png');
  }
  font-size: 2.5rem;
  ${media.desktop`
    font-size: 2.25rem;
  `}
  ${media.tablet`
    font-size: 2rem;
  `}
  ${media.phablet`
    font-size: 1.75rem;
  `}
  ${media.smallMobile`
    font-size: 1.5rem;
  `}
  ${media.tinyMobile`
    font-size: 1.4rem;
  `}
  .emoji {
    width: 40px;
    height: 40px;
    ${media.desktop`
      width: 35px;
      height: 35px;
    `}
    ${media.tablet`
      width: 30px;
      height: 30px;
    `}
    ${media.smallMobile`
      width: 25px;
      height: 25px;
    `}
  }
`;

const Contact = styled.h3`
  font-size: 1.25rem;
  font-weight: 400;
  ${media.phablet`
    font-size: 1rem;
  `}
  .emoji {
    &.pointer {
      background-image: url('./img/pointright.png');
      width: 30px;
      height: 30px;
      margin: 0 5px;
      vertical-align: text-bottom;
      ${media.phablet`
        width: 25px;
        height: 25px;
      `}
    }
  }
  .highlight-link {
    transition: ${transition};
    font-weight: 400;
    text-decoration: none;
    display: inline-block;
    padding: 2px 3px;
    text-decoration: none;
    box-shadow: inset 0 -3px 0 ${colorsAndFonts.blue};
    &:hover {
      box-shadow: inset 0 -33px 0 0 ${colorsAndFonts.blue};
      color: ${colorsAndFonts.white};
    }
  }
`;

const Header = () => {
  const [className, setClassName] = useState('wave');

  const animationEnd = () => {
    setClassName('');
  };

  const mouseOver = () => {
    setClassName('wave');
  };

  return (
    <Container className="intro">
      <ProfileImage src="./img/profile_picture.jpeg" alt="Hemant Joshi" />
      <HelloIntro>
        Hello!
        <span
          className={`emoji ${className} wave-hand animated`}
          onAnimationEnd={animationEnd}
          onMouseOver={mouseOver}
          onMouseOut={animationEnd}
        >
          {' '}
        </span>
      </HelloIntro>

      <TagLine>
        I'm
        <span className="name"> Hemant Joshi</span>, a software engineer focused
        on building solutions using concepts of Linux &amp; Networking
        <span className="emoji technologist"></span>
      </TagLine>

      <Contact>
        <span>Get in touch </span>
        <span className="emoji pointer"></span>
        <span>
          <a
            href="mailto:mail@hjoshi.me"
            rel="noreferrer"
            target="_blank"
            className="highlight-link"
          >
            mail@hjoshi.me
          </a>
        </span>
      </Contact>
    </Container>
  );
};

export default Header;
