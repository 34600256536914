import styled from 'styled-components';
import { useEffect } from 'react';

import {
  Section,
  SectionContent,
  SectionTitle,
  ArrowStyle,
} from '../style/styles';
import { colorsAndFonts } from '../style/constants';
import sr from '../style/ScrollReveal';
import { otherProjects } from '../data/otherProjects';

const Projects = styled.div`
  max-width: 600px;
  margin-bottom: 50px;
  .name {
    margin-bottom: 10px;
  }
  p {
    font-size: 0.9rem;
    margin-bottom: 20px;
  }
  .item {
    display: inline-block;
    margin: 3px 3px 3px 0;
    font-family: 'Inconsolata', monospace;
    font-size: 0.75rem;
    font-weight: 700;
    padding: 2px 7px;
    border-radius: 3px;
    color: ${colorsAndFonts.blue};
    border: 1px solid ${colorsAndFonts.blue};
    background: transparent;
    &:first-of-type {
      margin-left: 0;
    }
  }
`;

const OtherProjects = () => {
  useEffect(() => {
    const config = {
      reset: false,
      duration: 600,
      easing: 'cubic-bezier(.694,0,.335,1)',
      scale: 1,
      viewFactor: 0.05,
    };

    sr.reveal('.other_projects', config);
  }, []);

  return (
    <Section className="other_projects">
      <SectionTitle>Projects</SectionTitle>
      <SectionContent>
        {otherProjects.map((value, index) => {
          return (
            <Projects key={index}>
              <div className="name">
                <ArrowStyle href={value.url} target="_blank" rel="noreferrer">
                  {value.name}
                </ArrowStyle>
              </div>
              <p>{value.description}</p>
              <div className="used">
                {value.used.map((val, idx) => {
                  return (
                    <span className="item" key={idx}>
                      {val}
                    </span>
                  );
                })}
              </div>
            </Projects>
          );
        })}
      </SectionContent>
    </Section>
  );
};

export default OtherProjects;
