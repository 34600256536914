import { ThemeProvider } from 'styled-components';

import { GlobalStyles } from './style/styles';
import Context from './context';
import { light, dark } from './style/constants';
import Switch from './components/Switch';
import Header from './components/Header';
import Background from './components/Background';
import Skills from './components/Skills';
import Experience from './components/Experience';
import OtherProjects from './components/OtherProjects';
import Featured from './components/Featured';
import Footer from './components/Footer';
import TopButtonComponent from './components/TopButton';
import { useDarkMode } from './useDarkMode';

function App() {
  const [theme, toggleTheme] = useDarkMode();
  return (
    <Context.Provider value={theme}>
      <ThemeProvider theme={theme === 'light'? light : dark}>
        <>
          <GlobalStyles />
          <Switch toggleTheme={toggleTheme} />
          <Header />
          <Background />
          <Skills />
          <Experience />
          <Featured />
          <OtherProjects />
          <Footer />
          <TopButtonComponent />
        </>
      </ThemeProvider>
    </Context.Provider>
  );
}

export default App;
