import { useEffect, useState } from 'react';
import { TopButton } from '../style/styles';

const TopButtonComponent = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    const introHeight = document.querySelector('.intro').offsetHeight;
    if (window.scrollY > introHeight) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <>
      {isVisible ? (
        <TopButton onClick={scrollTop}>
          <img src="./img/pointing-up.png" alt="" />
        </TopButton>
      ) : null}
    </>
  );
};

export default TopButtonComponent;
