export const otherProjects = [
  {
    name: 'hjoshi.me',
    url: 'https://hjoshi.me',
    code: 'https://github.com/hjoshi123/hjoshi.me',
    description: 'Personal website based on React JS',
    used: ['React', 'Bash', 'Docker', 'Traefik', 'Nginx', 'CentOS']
  },
  {
    name: 'Seguro Droga',
    url: 'https://github.com/Seguro-Droga',
    code: 'https://github.com/Seguro-Droga',
    description: 'Runner up for Microsoft Imagine Cup Asia Regionals 2020. Hyperledger Fabric based project to detect counterfeit drugs using RFID strips printed on the medicine.',
    used: ['React', 'Docker', 'Hyperledger', 'Go']
  },
  {
    name: 'M1DevSetup',
    url: 'https://github.com/hjoshi123/M1DevSetup',
    code: 'https://github.com/hjoshi123/M1DevSetup',
    description: 'Script tool to install dependencies and setup dev environment on Apple Silicon Macs',
    used: ['Bash', 'zsh']
  },
  {
    name: 'NCli',
    url: 'https://github.com/hjoshi123/NCli',
    code: 'https://github.com/hjoshi123/NCli',
    description:
      'Open Source CLI tool which allows users to check IP, CNAME or MX records of a given domain',
    used: ['Golang', 'Bash', 'Travis CI'],
  },
  {
    name: 'LEarning app for Visually Impaired (LEVI)',
    url: 'https://github.com/hjoshi123/VoiceApp',
    code: 'https://github.com/hjoshi123/VoiceApp',
    description:
      'App for visually impaired students to learn basic mathematics through use of numpad. Built using the concept of Finite State Machines.',
    used: ['Java', 'Android', 'FSM (Finite State Machines)'],
  },
  {
    name: 'HopOn-Proxy',
    url: 'https://github.com/hjoshi123/Hopon-Proxy',
    code: 'https://github.com/hjoshi123/Hopon-Proxy',
    description:
      "HTTP/HTTP(S) proxy built using Golang and Golang's net module.",
    used: ['Golang', 'Docker', 'Networking'],
  },
];
