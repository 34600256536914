const font = `'Source Sans Pro', system, system-ui, -apple-system,
  BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif`;

export const colorsAndFonts = {
  night: '#171c28',
  black: '#36363c',
  darkGrey: '#444452',
  grey: '#777777',
  blue: '#007bff',
  slate: '#afafbf',
  offWhite: '#e7e7e7',
  white: '#ffffff',
  green: '#bae67e',
  purple: '#7d0ce8',
  red: '#ff0000',
  orange: '#e8850c',
  yellow: '#ffdc00',
  accent: '#ffcc66',
  fontStack: font,
  Apercu: `'Apercu', ${font}`,
};

export const transition = `all 0.2s ease-in-out;`;

export const light = {
  background: colorsAndFonts.white,
  color: colorsAndFonts.darkGrey,
  backgroundSun: `url('./img/sun.svg') no-repeat center`,
  backgroundMoon: `url('./img/moon.svg') no-repeat center`,
  strongColor: colorsAndFonts.black,
};

export const dark = {
  background: colorsAndFonts.night,
  color: colorsAndFonts.slate,
  selectionColor: colorsAndFonts.black,
  backgroundSelectionColor: colorsAndFonts.yellow,
  backgroundSun: `url('./img/sun-white.svg') no-repeat center`,
  backgroundMoon: `url('./img/moon-white.svg') no-repeat center`,
  strongColor: colorsAndFonts.offWhite,
};
