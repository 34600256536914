import React, { useContext } from 'react';
import styled from 'styled-components';

import { transition, colorsAndFonts } from '../style/constants';
import media from '../style/media';
import Context from '../context';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 30px;

  ${media.mobile`
    top: 10px;
    right: 20px;`}

  .sun, .moon {
    width: 17px;
    height: 17px;
  }
  .sun {
    background: ${({ theme }) => theme.backgroundSun};
    background-size: 100%;
  }
  .moon {
    background: ${({ theme }) => theme.backgroundMoon};
    background-size: 100%;
  }
  .toggle-wrapper {
    margin: 10px;
    #switch {
      height: 0;
      width: 0;
      visibility: hidden;
      position: absolute;
      &:checked + label {
        background: ${colorsAndFonts.green};
      }
      &:checked + label:after {
        left: calc(100% - 3px);
        transform: translateX(-100%);
      }
    }

    label {
      cursor: pointer;
      text-indent: -9999px;
      width: 45px;
      height: 25px;
      background: grey;
      display: block;
      border-radius: 100px;
      position: relative;
      &:after {
        transition: ${transition};
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: 19px;
        height: 19px;
        background: #fff;
        border-radius: 100%;
      }
    }
  }
`;

const Switch = ({ toggleTheme }) => {
  const themeCon = useContext(Context);
  return (
    <Container>
      <div className="sun"></div>
      <div className="toggle-wrapper">
        <input
          id="switch"
          type="checkbox"
          checked={themeCon === 'light' ? false : true}
          onClick={toggleTheme}
        />
        <label htmlFor="switch" id="toggle">
          Toggle
        </label>
      </div>
      <div className="moon"></div>
    </Container>
  );
};

export default Switch;
