import styled, { createGlobalStyle } from 'styled-components';

import { colorsAndFonts, transition } from './constants';
import media from './media';

import ApLightEot from '../fonts/Apercu-Light.eot';
import ApLightWoff2 from '../fonts/Apercu-Light.woff2';
import ApLightWoff from '../fonts/Apercu-Light.woff';
import ApLightTTF from '../fonts/Apercu-Light.ttf';
import ApLightSVG from '../fonts/Apercu-Light.svg';

import ApLightItalicEot from '../fonts/Apercu-LightItalic.eot';
import ApLightItalicWoff2 from '../fonts/Apercu-LightItalic.woff2';
import ApLightItalicWoff from '../fonts/Apercu-LightItalic.woff';
import ApLightItalicTTF from '../fonts/Apercu-LightItalic.ttf';
import ApLightItalicSVG from '../fonts/Apercu-LightItalic.svg';

import ApRegularEot from '../fonts/Apercu-Regular.eot';
import ApRegularWoff2 from '../fonts/Apercu-Regular.woff2';
import ApRegularWoff from '../fonts/Apercu-Regular.woff';
import ApRegularTTF from '../fonts/Apercu-Regular.ttf';
import ApRegularSVG from '../fonts/Apercu-Regular.svg';

import ApItalicEot from '../fonts/Apercu-Italic.eot';
import ApItalicWoff2 from '../fonts/Apercu-Italic.woff2';
import ApItalicWoff from '../fonts/Apercu-Italic.woff';
import ApItalicTTF from '../fonts/Apercu-Italic.ttf';
import ApItalicSVG from '../fonts/Apercu-Italic.svg';

import ApMediumEot from '../fonts/Apercu-Medium.eot';
import ApMediumWoff2 from '../fonts/Apercu-Medium.woff2';
import ApMediumWoff from '../fonts/Apercu-Medium.woff';
import ApMediumTTF from '../fonts/Apercu-Medium.ttf';
import ApMediumSVG from '../fonts/Apercu-Medium.svg';

import ApMediumItalicEot from '../fonts/Apercu-MediumItalic.eot';
import ApMediumItalicWoff2 from '../fonts/Apercu-MediumItalic.woff2';
import ApMediumItalicWoff from '../fonts/Apercu-MediumItalic.woff';
import ApMediumItalicTTF from '../fonts/Apercu-MediumItalic.ttf';
import ApMediumItalicSVG from '../fonts/Apercu-MediumItalic.svg';

import ApBoldEot from '../fonts/Apercu-Bold.eot';
import ApBoldWoff2 from '../fonts/Apercu-Bold.woff2';
import ApBoldWoff from '../fonts/Apercu-Bold.woff';
import ApBoldTTF from '../fonts/Apercu-Bold.ttf';
import ApBoldSVG from '../fonts/Apercu-Bold.svg';

import ApBoldItalicEot from '../fonts/Apercu-BoldItalic.eot';
import ApBoldItalicWoff2 from '../fonts/Apercu-BoldItalic.woff2';
import ApBoldItalicWoff from '../fonts/Apercu-BoldItalic.woff';
import ApBoldItalicTTF from '../fonts/Apercu-BoldItalic.ttf';
import ApBoldItalicSVG from '../fonts/Apercu-BoldItalic.svg';

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Apercu';
    src: url(${ApLightEot});
    src: url('${ApLightEot}#iefix') format('embedded-opentype'),
      url(${ApLightWoff2}) format('woff2'),
      url(${ApLightWoff}) format('woff'),
      url(${ApLightTTF}) format('truetype'),
      url('${ApLightSVG}#Apercu-Light') format('svg');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Apercu';
    src: url(${ApLightItalicEot});
    src: url('${ApLightItalicEot}?#iefix') format('embedded-opentype'),
      url(${ApLightItalicWoff2}) format('woff2'),
      url(${ApLightItalicWoff}) format('woff'),
      url(${ApLightItalicTTF}) format('truetype'),
      url('${ApLightItalicSVG}#Apercu-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'Apercu';
    src: url(${ApRegularEot});
    src: url('${ApRegularEot}?#iefix') format('embedded-opentype'),
      url(${ApRegularWoff2}) format('woff2'),
      url(${ApRegularWoff}) format('woff'),
      url(${ApRegularTTF}) format('truetype'),
      url('${ApRegularSVG}#Apercu-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Apercu';
    src: url(${ApItalicEot});
    src: url('${ApItalicEot}?#iefix') format('embedded-opentype'),
      url(${ApItalicWoff2}) format('woff2'),
      url(${ApItalicWoff}) format('woff'),
      url(${ApItalicTTF}) format('truetype'),
      url('${ApItalicSVG}#Apercu-Italic') format('svg');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'Apercu';
    src: url(${ApMediumEot});
    src: url('${ApMediumEot}?#iefix') format('embedded-opentype'),
      url(${ApMediumWoff2}) format('woff2'),
      url(${ApMediumWoff}) format('woff'),
      url(${ApMediumTTF}) format('truetype'),
      url('${ApMediumSVG}#Apercu-Italic') format('svg');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Apercu';
    src: url(${ApMediumItalicEot});
    src: url('${ApMediumItalicEot}?#iefix') format('embedded-opentype'),
      url(${ApMediumItalicWoff2}) format('woff2'),
      url(${ApMediumItalicWoff}) format('woff'),
      url(${ApMediumItalicTTF}) format('truetype'),
      url('${ApMediumItalicSVG}#Apercu-Italic') format('svg');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'Apercu';
    src: url(${ApBoldEot});
    src: url('${ApBoldEot}?#iefix') format('embedded-opentype'),
      url(${ApBoldWoff2}) format('woff2'),
      url(${ApBoldWoff}) format('woff'),
      url(${ApBoldTTF}) format('truetype'),
      url('${ApBoldSVG}#Apercu-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Apercu';
    src: url(${ApBoldItalicEot});
    src: url('${ApBoldItalicEot}?#iefix') format('embedded-opentype'),
      url(${ApBoldItalicWoff2}) format('woff2'),
      url(${ApBoldItalicWoff}) format('woff'),
      url(${ApBoldItalicTTF}) format('truetype'),
      url('${ApBoldItalicSVG}#Apercu-Bold') format('svg');
    font-weight: 700;
    font-style: italic;
  }

  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html, body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${colorsAndFonts.Apercu};
    line-height: 1.5;
    width: 100%;
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
  }

  ::selection {
    color: ${({ theme }) => theme.selectionColor};
    background: ${({ theme }) => theme.backgroundSelectionColor};;
  };

  .emoji {
    display: inline-block;
    vertical-align: text-top;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  a {
    transition: ${transition};
    text-decoration: none;
    font-weight: 700;
    color: inherit;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
  
  img {
    max-width: 100%;
    vertical-align: middle;
  }
  
  a, .intro, .label, .status {
    color: ${({ theme }) => theme.strongColor};
  }

  strong {
    font-weight: 700;
    color: ${({ theme }) => theme.strongColor};
  }

  .waypoint {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
    transition: opacity 0.6s cubic-bezier(0.694, 0, 0.335, 1),
      transform 0.6s cubic-bezier(0.694, 0, 0.335, 1);
  }
`;

export const Section = styled.section`
  display: flex;
  justify-content: center;
  padding: 100px 170px;
  ${media.thirteen`
    padding: 100px;
  `}
  ${media.desktop`
    padding: 50px;
  `}
  ${media.tablet`
    display: block;
  `}
  ${media.mobile`
    padding: 50px 35px;
  `}
`;

export const SectionTitle = styled.div`
  flex-shrink: 0;
  width: 200px;
  margin-right: 70px;
  color: ${colorsAndFonts.blue};
  text-transform: uppercase;
  text-align: right;
  font-weight: 700;
  letter-spacing: 2px;
  ${media.desktop`
    margin-right: 50px;
  `}
  ${media.littleDesktop`
    width: 150px;
  `}
  ${media.tablet`
    text-align: left;
    width: auto;
  `}
`;

export const SectionContent = styled.div`
  font-weight: 300;
  font-size: 1rem;
  width: 100%;
  max-width: 650px;
  ${media.tablet`
    padding-top: 30px;
    padding-left: 50px;
  `}
  ${media.mobile`
    padding-left: 30px;
  `}
  p {
    margin-top: 0;
    margin-bottom: 25px;
  }
  .underline-link {
    position: relative;
    display: inline-block;
    &:after {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 1px;
      background: $black;
      content: '';
      opacity: 0;
      transition: height 0.3s, opacity 0.3s, transform 0.3s;
      transform: translateY(-5px);
    }
    &:hover,
    &:focus {
      &:after {
        height: 2px;
        border-radius: 3px;
        opacity: 1;
        transform: translateY(-2px);
      }
    }
  }
`;

export const ArrowStyle = styled.a`
  transition: ${transition};
  font-weight: 700;
  display: inline-block;
  background-color: transparent;
  white-space: nowrap;
  &:after {
    transition: ${transition};
    position: relative;
    bottom: 2px;
    margin-left: 15px;
    content: '';
    background: url('./img/arrow.png') no-repeat center;
    background-size: 100%;
    width: 15px;
    height: 15px;
    display: inline-block;
    vertical-align: middle;
  }
  &:hover:after {
    transform: translateX(7px);
  }
`;

export const TopButton = styled.button`=
  transition: ${transition};
  background: transparent;
  border: none;
  outline: none;
  width: 40px;
  position: fixed;
  bottom: 25px;
  right: 10px;
  cursor: pointer;
  display: none;
  ${media.desktop`
    bottom: 20px;
    right: 7px;
    width: 35px;
  `}
  ${media.mobile`
    bottom: 15px;
    right: 3px;
  `}

  &:hover,
  &:focus {
    transform: translateY(-10px);
  }
  img {
    width: 100%;
  }
`;
