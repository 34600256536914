import { css } from 'styled-components'

const sizes = {
  fifteen: '1440',
  thirteen: '1280',
  desktop: '1024',
  littleDesktop: '850',
  tablet: '768',
  weirdMedium: '630',
  phablet: '550',
  mobile: '480',
  smallMobile: '360',
  tinyMobile: '330',
};

export default Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
     @media (max-width: ${sizes[label]}px) {
        ${css(...args)};
     }
  `
  return acc
}, {});