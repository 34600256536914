export const social = [
  {
    url: 'mailto:mail@hjoshi.me',
    title: 'email',
    imageLink: '/img/email.svg',
  },
  {
    url: 'https://twitter.com/hemantj0',
    title: 'Twitter',
    imageLink: './img/twitter.svg',
  },
  {
    url: 'https://github.com/hjoshi123',
    title: 'Github',
    imageLink: './img/github.svg'
  },
  {
    url: 'https://www.linkedin.com/in/hemantj0',
    title: 'LinkedIn',
    imageLink: './img/linkedin.svg'
  },
  {
    url: 'https://blog.hjoshi.me',
    title: 'Blog',
    imageLink: './img/feather.svg'
  }
];
