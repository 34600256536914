import { useEffect } from 'react';
import styled from 'styled-components';

import { Section, SectionContent, SectionTitle } from '../style/styles';
import media from '../style/media';
import sr from '../style/ScrollReveal';

const Project = styled.div`
  display: flex;
  margin-bottom: 100px;
  margin-left: -5%;
  width: 125%;
  ${media.thirteen`
    width: 115%;
  `};

  ${media.desktop`
    width: 105%;
  `}

  ${media.tablet`
    width: 100%;
    display: block;
    margin-bottom: 70px;
  `}

  .pic {
    max-width: 700px;
    overflow: visible;
    margin: 0;
    a {
      display: block;
    }
    img {
      width: 100%;
      max-width:100%;
      vertical-align: bottom;
    }
  }

  .caption {
    width: 125px;
    flex-shrink: 0;
    margin-left: 30px;
    ${media.tablet`
      width: 100%;
      margin-top: 25px;
      margin-left: 0;
    `}
  }
  .name {
    margin-bottom: 10px;
    a {
      font-weight: 700;
    }
  }
  p {
    font-size: 0.85rem;
    line-height: 1.3;
  }
`;

const Featured = () => {
  useEffect(() => {
    const config = {
      reset: false,
      duration: 600,
      easing: 'cubic-bezier(.694,0,.335,1)',
      scale: 1,
      viewFactor: 0.1,
    };

    sr.reveal('.featured', config);
  }, []);

  return (
    <Section className="featured">
      <SectionTitle>Featured</SectionTitle>
      <SectionContent>
        <Project>
          <figure className="pic">
            <a href="https://blog.hjoshi.me/" rel="noreferrer" target="_blank">
              <img src="./img/blog.png" alt="" />
            </a>
          </figure>
          <figcaption className="caption">
            <div class="project__name">
              <a href="https://blog.hjoshi.me/"  rel="noreferrer" target="_blank">Lazy Coder</a>
            </div>
            <p>Blog for my projects and explorations</p>
          </figcaption>
        </Project>
      </SectionContent>
    </Section>
  );
};

export default Featured;