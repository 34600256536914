export const experience = [
  {
    company: 'Seniorly',
    url: 'https://www.seniorly.com',
    time: 'May 2023 - Present',
    position: 'Software Engineer Intern',
  },
  {
    company: 'University of Colorado Boulder',
    url: 'https://www.colorado.edu/cs/',
    time: 'Aug 2022 - Present',
    position: 'Graduate Student (MSCPS)',
  },
  {
    company: 'All Remote Solutions Private Limited',
    url: 'https://www.skuad.io/',
    time: 'Nov 2021 - Aug 2022',
    position: 'Software Engineer',
  },
  {
    company: 'McAfee',
    url: 'https://www.mcafee.com',
    time: 'Aug 2020 - Nov 2021',
    position: 'Software Development Engineer',
  },
  // {
  //   company: 'McAfee',
  //   url: 'https://www.mcafee.com',
  //   time: 'Feb 2020 - Aug 2020',
  //   position: 'Technical Intern'
  // },
  // {
  //   company: 'MSRIT',
  //   url: 'http://www.msrit.edu',
  //   time: 'Aug 2017 - Aug 2020',
  //   position: 'Facilitator at Developer Student Club (DSC), Google'
  // },
  // {
  //   company: 'Nuvonic',
  //   url: 'https://www.linkedin.com/company/nuvonic/about/',
  //   time: 'Feb 2019 - Sep 2019',
  //   position: 'Lead Developer'
  // }
];
