import styled from 'styled-components';

import media from '../style/media';
import { social } from '../data/social';
import { colorsAndFonts } from '../style/constants';

const FooterContainer = styled.footer`
  padding: 50px 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media.desktop`
    padding: 50px;
  `}
  ${media.weirdMedium`
    display: block;
  `}
  ${media.mobile`
    padding: 50px 30px;
  `}
`;

const FooterCopyright = styled.div`
  font-weight: 700;
  width: 200px;
  ${media.weirdMedium`
    width: auto;
    text-align: center;
    margin-bottom: 25px;
  `}
  .top, .bottom {
    ${media.weirdMedium`
      display: inline-block;
    `}
    ${media.mobile`
      display: block;
    `}
  }
  .emoji {
    width: 20px;
    height: 20px;
    margin: 0 3px;
    vertical-align: text-bottom;
  }
`;

const FooterLinks = styled.div`
  ${media.weirdMedium`
    display: flex;
    justify-content: center;
  `}
  a {
    position: relative;
    display: inline-block;
    color: ${colorsAndFonts.blue} !important;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 15px;
    outline: none;
    ${media.desktop`
      margin: 0 10px;
    `}
    &:after {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 1px;
      background: ${colorsAndFonts.blue};
      content: '';
      opacity: 0;
      transition: height 0.3s, opacity 0.3s, transform 0.3s;
      transform: translateY(-5px);
      ${media.littleDesktop`
        display: none;
      `}
    }
    &:hover:after,
    &:focus:after {
      height: 2px;
      border-radius: 3px;
      opacity: 1;
      transform: translateY(0px);
    }
    .text {
      font-size: 0.9rem;
      display: inline;
      letter-spacing: 1px;
      ${media.littleDesktop`
        display: none;
      `}
    }
  }
  img {
    display: none;
    width: 22px;
    ${media.littleDesktop`
      display: inline-block;
    `}
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterCopyright>
        <div className="top">
          <span>Developed by</span>
        </div>
        <div className="bottom">
          <span>Hemant Joshi</span>
          <span> {(new Date().getFullYear())}</span>
        </div>
      </FooterCopyright>
      <FooterLinks>
        {social.map((value, index) => {
          return (
            <a href={value.url} target="_blank" rel="noreferrer" title={value.title} key={index}>
              <span className="text">{value.title}</span>
              <img src={value.imageLink} alt={value.title} />
            </a>
          );
        })}
      </FooterLinks>
    </FooterContainer>
  );
};

export default Footer;
